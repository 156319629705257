// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/vercel/path0/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-index-js": () => import("/vercel/path0/src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-news-asani-ska-js": () => import("/vercel/path0/src/pages/en/news/asani_ska.js" /* webpackChunkName: "component---src-pages-en-news-asani-ska-js" */),
  "component---src-pages-en-news-glushkov-mom-js": () => import("/vercel/path0/src/pages/en/news/glushkov_mom.js" /* webpackChunkName: "component---src-pages-en-news-glushkov-mom-js" */),
  "component---src-pages-en-news-krapukhin-four-goal-contributions-in-debut-js": () => import("/vercel/path0/src/pages/en/news/krapukhin_four_goal_contributions_in_debut.js" /* webpackChunkName: "component---src-pages-en-news-krapukhin-four-goal-contributions-in-debut-js" */),
  "component---src-pages-en-news-krapukhin-signs-with-catenaccio-js": () => import("/vercel/path0/src/pages/en/news/krapukhin_signs_with_catenaccio.js" /* webpackChunkName: "component---src-pages-en-news-krapukhin-signs-with-catenaccio-js" */),
  "component---src-pages-en-news-krapukhin-transfers-to-riga-js": () => import("/vercel/path0/src/pages/en/news/krapukhin_transfers_to_riga.js" /* webpackChunkName: "component---src-pages-en-news-krapukhin-transfers-to-riga-js" */),
  "component---src-pages-en-news-lemajic-best-2019-js": () => import("/vercel/path0/src/pages/en/news/lemajic_best_2019.js" /* webpackChunkName: "component---src-pages-en-news-lemajic-best-2019-js" */),
  "component---src-pages-en-news-lemajic-transfers-to-gent-js": () => import("/vercel/path0/src/pages/en/news/lemajic_transfers_to_gent.js" /* webpackChunkName: "component---src-pages-en-news-lemajic-transfers-to-gent-js" */),
  "component---src-pages-en-news-maksimov-torpedo-js": () => import("/vercel/path0/src/pages/en/news/maksimov_torpedo.js" /* webpackChunkName: "component---src-pages-en-news-maksimov-torpedo-js" */),
  "component---src-pages-en-news-maksimov-vs-fifa-in-cas-js": () => import("/vercel/path0/src/pages/en/news/maksimov_vs_fifa_in_cas.js" /* webpackChunkName: "component---src-pages-en-news-maksimov-vs-fifa-in-cas-js" */),
  "component---src-pages-en-news-maksimov-vs-fifa-js": () => import("/vercel/path0/src/pages/en/news/maksimov_vs_fifa.js" /* webpackChunkName: "component---src-pages-en-news-maksimov-vs-fifa-js" */),
  "component---src-pages-en-news-mashal-new-client-for-legal-js": () => import("/vercel/path0/src/pages/en/news/mashal_new_client_for_legal.js" /* webpackChunkName: "component---src-pages-en-news-mashal-new-client-for-legal-js" */),
  "component---src-pages-en-news-micevski-transfers-to-rfs-js": () => import("/vercel/path0/src/pages/en/news/micevski_transfers_to_rfs.js" /* webpackChunkName: "component---src-pages-en-news-micevski-transfers-to-rfs-js" */),
  "component---src-pages-en-news-ninua-first-goal-js": () => import("/vercel/path0/src/pages/en/news/ninua_first_goal.js" /* webpackChunkName: "component---src-pages-en-news-ninua-first-goal-js" */),
  "component---src-pages-en-news-ninua-loan-to-anorthosis-js": () => import("/vercel/path0/src/pages/en/news/ninua_loan_to_anorthosis.js" /* webpackChunkName: "component---src-pages-en-news-ninua-loan-to-anorthosis-js" */),
  "component---src-pages-en-news-ninua-paok-js": () => import("/vercel/path0/src/pages/en/news/ninua_paok.js" /* webpackChunkName: "component---src-pages-en-news-ninua-paok-js" */),
  "component---src-pages-en-news-osipov-about-liepaja-js": () => import("/vercel/path0/src/pages/en/news/osipov_about_liepaja.js" /* webpackChunkName: "component---src-pages-en-news-osipov-about-liepaja-js" */),
  "component---src-pages-en-news-osipov-contract-js": () => import("/vercel/path0/src/pages/en/news/osipov_contract.js" /* webpackChunkName: "component---src-pages-en-news-osipov-contract-js" */),
  "component---src-pages-en-news-osipov-vs-liepaja-js": () => import("/vercel/path0/src/pages/en/news/osipov_vs_liepaja.js" /* webpackChunkName: "component---src-pages-en-news-osipov-vs-liepaja-js" */),
  "component---src-pages-en-news-pafos-maslac-js": () => import("/vercel/path0/src/pages/en/news/pafos_maslac.js" /* webpackChunkName: "component---src-pages-en-news-pafos-maslac-js" */),
  "component---src-pages-en-news-poposki-extended-contract-with-riga-js": () => import("/vercel/path0/src/pages/en/news/poposki_extended_contract_with_riga.js" /* webpackChunkName: "component---src-pages-en-news-poposki-extended-contract-with-riga-js" */),
  "component---src-pages-en-news-poposki-first-head-coach-match-js": () => import("/vercel/path0/src/pages/en/news/poposki_first_head_coach_match.js" /* webpackChunkName: "component---src-pages-en-news-poposki-first-head-coach-match-js" */),
  "component---src-pages-en-news-poposki-js": () => import("/vercel/path0/src/pages/en/news/poposki.js" /* webpackChunkName: "component---src-pages-en-news-poposki-js" */),
  "component---src-pages-en-news-rodic-signs-with-riga-js": () => import("/vercel/path0/src/pages/en/news/rodic_signs_with_riga.js" /* webpackChunkName: "component---src-pages-en-news-rodic-signs-with-riga-js" */),
  "component---src-pages-en-news-saveljevs-extended-contract-js": () => import("/vercel/path0/src/pages/en/news/saveljevs_extended_contract.js" /* webpackChunkName: "component---src-pages-en-news-saveljevs-extended-contract-js" */),
  "component---src-pages-en-news-saveljevs-latvia-u-21-js": () => import("/vercel/path0/src/pages/en/news/saveljevs_latvia_u21.js" /* webpackChunkName: "component---src-pages-en-news-saveljevs-latvia-u-21-js" */),
  "component---src-pages-en-news-saveljevs-mantova-js": () => import("/vercel/path0/src/pages/en/news/saveljevs_mantova.js" /* webpackChunkName: "component---src-pages-en-news-saveljevs-mantova-js" */),
  "component---src-pages-en-news-saveljevs-sorokins-in-national-team-debut-js": () => import("/vercel/path0/src/pages/en/news/saveljevs_sorokins_in_national_team_debut.js" /* webpackChunkName: "component---src-pages-en-news-saveljevs-sorokins-in-national-team-debut-js" */),
  "component---src-pages-en-news-saveljevs-sorokins-in-national-team-js": () => import("/vercel/path0/src/pages/en/news/saveljevs_sorokins_in_national_team.js" /* webpackChunkName: "component---src-pages-en-news-saveljevs-sorokins-in-national-team-js" */),
  "component---src-pages-en-news-saveljevs-transfers-to-riga-js": () => import("/vercel/path0/src/pages/en/news/saveljevs_transfers_to_riga.js" /* webpackChunkName: "component---src-pages-en-news-saveljevs-transfers-to-riga-js" */),
  "component---src-pages-en-news-sorokin-prolongs-till-2023-js": () => import("/vercel/path0/src/pages/en/news/sorokin_prolongs_till_2023.js" /* webpackChunkName: "component---src-pages-en-news-sorokin-prolongs-till-2023-js" */),
  "component---src-pages-en-news-sorokins-extended-contract-rfs-js": () => import("/vercel/path0/src/pages/en/news/sorokins_extended_contract_rfs.js" /* webpackChunkName: "component---src-pages-en-news-sorokins-extended-contract-rfs-js" */),
  "component---src-pages-en-news-sorokins-latvia-2-js": () => import("/vercel/path0/src/pages/en/news/sorokins_latvia_2.js" /* webpackChunkName: "component---src-pages-en-news-sorokins-latvia-2-js" */),
  "component---src-pages-en-news-sorokins-latvia-js": () => import("/vercel/path0/src/pages/en/news/sorokins_latvia.js" /* webpackChunkName: "component---src-pages-en-news-sorokins-latvia-js" */),
  "component---src-pages-en-news-sushkin-contract-ended-js": () => import("/vercel/path0/src/pages/en/news/sushkin_contract_ended.js" /* webpackChunkName: "component---src-pages-en-news-sushkin-contract-ended-js" */),
  "component---src-pages-en-news-sushkin-contract-js": () => import("/vercel/path0/src/pages/en/news/sushkin_contract.js" /* webpackChunkName: "component---src-pages-en-news-sushkin-contract-js" */),
  "component---src-pages-en-players-glushkov-js": () => import("/vercel/path0/src/pages/en/players/glushkov.js" /* webpackChunkName: "component---src-pages-en-players-glushkov-js" */),
  "component---src-pages-en-players-lasha-js": () => import("/vercel/path0/src/pages/en/players/lasha.js" /* webpackChunkName: "component---src-pages-en-players-lasha-js" */),
  "component---src-pages-en-players-lemajic-js": () => import("/vercel/path0/src/pages/en/players/lemajic.js" /* webpackChunkName: "component---src-pages-en-players-lemajic-js" */),
  "component---src-pages-en-players-maksimov-js": () => import("/vercel/path0/src/pages/en/players/maksimov.js" /* webpackChunkName: "component---src-pages-en-players-maksimov-js" */),
  "component---src-pages-en-players-osipov-js": () => import("/vercel/path0/src/pages/en/players/osipov.js" /* webpackChunkName: "component---src-pages-en-players-osipov-js" */),
  "component---src-pages-en-players-saveljevs-js": () => import("/vercel/path0/src/pages/en/players/saveljevs.js" /* webpackChunkName: "component---src-pages-en-players-saveljevs-js" */),
  "component---src-pages-en-players-sorokin-js": () => import("/vercel/path0/src/pages/en/players/sorokin.js" /* webpackChunkName: "component---src-pages-en-players-sorokin-js" */),
  "component---src-pages-index-js": () => import("/vercel/path0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-asani-ska-js": () => import("/vercel/path0/src/pages/news/asani_ska.js" /* webpackChunkName: "component---src-pages-news-asani-ska-js" */),
  "component---src-pages-news-glushkov-mom-js": () => import("/vercel/path0/src/pages/news/glushkov_mom.js" /* webpackChunkName: "component---src-pages-news-glushkov-mom-js" */),
  "component---src-pages-news-krapukhin-four-goal-contributions-in-debut-js": () => import("/vercel/path0/src/pages/news/krapukhin_four_goal_contributions_in_debut.js" /* webpackChunkName: "component---src-pages-news-krapukhin-four-goal-contributions-in-debut-js" */),
  "component---src-pages-news-krapukhin-signs-with-catenaccio-js": () => import("/vercel/path0/src/pages/news/krapukhin_signs_with_catenaccio.js" /* webpackChunkName: "component---src-pages-news-krapukhin-signs-with-catenaccio-js" */),
  "component---src-pages-news-krapukhin-transfers-to-riga-js": () => import("/vercel/path0/src/pages/news/krapukhin_transfers_to_riga.js" /* webpackChunkName: "component---src-pages-news-krapukhin-transfers-to-riga-js" */),
  "component---src-pages-news-lemajic-best-2019-js": () => import("/vercel/path0/src/pages/news/lemajic_best_2019.js" /* webpackChunkName: "component---src-pages-news-lemajic-best-2019-js" */),
  "component---src-pages-news-lemajic-transfers-to-gent-js": () => import("/vercel/path0/src/pages/news/lemajic_transfers_to_gent.js" /* webpackChunkName: "component---src-pages-news-lemajic-transfers-to-gent-js" */),
  "component---src-pages-news-maksimov-torpedo-js": () => import("/vercel/path0/src/pages/news/maksimov_torpedo.js" /* webpackChunkName: "component---src-pages-news-maksimov-torpedo-js" */),
  "component---src-pages-news-maksimov-vs-fifa-in-cas-js": () => import("/vercel/path0/src/pages/news/maksimov_vs_fifa_in_cas.js" /* webpackChunkName: "component---src-pages-news-maksimov-vs-fifa-in-cas-js" */),
  "component---src-pages-news-maksimov-vs-fifa-js": () => import("/vercel/path0/src/pages/news/maksimov_vs_fifa.js" /* webpackChunkName: "component---src-pages-news-maksimov-vs-fifa-js" */),
  "component---src-pages-news-mashal-new-client-for-legal-js": () => import("/vercel/path0/src/pages/news/mashal_new_client_for_legal.js" /* webpackChunkName: "component---src-pages-news-mashal-new-client-for-legal-js" */),
  "component---src-pages-news-micevski-transfers-to-rfs-js": () => import("/vercel/path0/src/pages/news/micevski_transfers_to_rfs.js" /* webpackChunkName: "component---src-pages-news-micevski-transfers-to-rfs-js" */),
  "component---src-pages-news-ninua-first-goal-js": () => import("/vercel/path0/src/pages/news/ninua_first_goal.js" /* webpackChunkName: "component---src-pages-news-ninua-first-goal-js" */),
  "component---src-pages-news-ninua-loan-to-anorthosis-js": () => import("/vercel/path0/src/pages/news/ninua_loan_to_anorthosis.js" /* webpackChunkName: "component---src-pages-news-ninua-loan-to-anorthosis-js" */),
  "component---src-pages-news-ninua-paok-js": () => import("/vercel/path0/src/pages/news/ninua_paok.js" /* webpackChunkName: "component---src-pages-news-ninua-paok-js" */),
  "component---src-pages-news-osipov-about-liepaja-js": () => import("/vercel/path0/src/pages/news/osipov_about_liepaja.js" /* webpackChunkName: "component---src-pages-news-osipov-about-liepaja-js" */),
  "component---src-pages-news-osipov-contract-js": () => import("/vercel/path0/src/pages/news/osipov_contract.js" /* webpackChunkName: "component---src-pages-news-osipov-contract-js" */),
  "component---src-pages-news-osipov-vs-liepaja-js": () => import("/vercel/path0/src/pages/news/osipov_vs_liepaja.js" /* webpackChunkName: "component---src-pages-news-osipov-vs-liepaja-js" */),
  "component---src-pages-news-pafos-maslac-js": () => import("/vercel/path0/src/pages/news/pafos_maslac.js" /* webpackChunkName: "component---src-pages-news-pafos-maslac-js" */),
  "component---src-pages-news-poposki-extended-contract-with-riga-js": () => import("/vercel/path0/src/pages/news/poposki_extended_contract_with_riga.js" /* webpackChunkName: "component---src-pages-news-poposki-extended-contract-with-riga-js" */),
  "component---src-pages-news-poposki-first-head-coach-match-js": () => import("/vercel/path0/src/pages/news/poposki_first_head_coach_match.js" /* webpackChunkName: "component---src-pages-news-poposki-first-head-coach-match-js" */),
  "component---src-pages-news-poposki-js": () => import("/vercel/path0/src/pages/news/poposki.js" /* webpackChunkName: "component---src-pages-news-poposki-js" */),
  "component---src-pages-news-rodic-signs-with-riga-js": () => import("/vercel/path0/src/pages/news/rodic_signs_with_riga.js" /* webpackChunkName: "component---src-pages-news-rodic-signs-with-riga-js" */),
  "component---src-pages-news-saveljevs-extended-contract-js": () => import("/vercel/path0/src/pages/news/saveljevs_extended_contract.js" /* webpackChunkName: "component---src-pages-news-saveljevs-extended-contract-js" */),
  "component---src-pages-news-saveljevs-latvia-u-21-js": () => import("/vercel/path0/src/pages/news/saveljevs_latvia_u21.js" /* webpackChunkName: "component---src-pages-news-saveljevs-latvia-u-21-js" */),
  "component---src-pages-news-saveljevs-mantova-js": () => import("/vercel/path0/src/pages/news/saveljevs_mantova.js" /* webpackChunkName: "component---src-pages-news-saveljevs-mantova-js" */),
  "component---src-pages-news-saveljevs-sorokins-in-national-team-debut-js": () => import("/vercel/path0/src/pages/news/saveljevs_sorokins_in_national_team_debut.js" /* webpackChunkName: "component---src-pages-news-saveljevs-sorokins-in-national-team-debut-js" */),
  "component---src-pages-news-saveljevs-sorokins-in-national-team-js": () => import("/vercel/path0/src/pages/news/saveljevs_sorokins_in_national_team.js" /* webpackChunkName: "component---src-pages-news-saveljevs-sorokins-in-national-team-js" */),
  "component---src-pages-news-saveljevs-transfers-to-riga-js": () => import("/vercel/path0/src/pages/news/saveljevs_transfers_to_riga.js" /* webpackChunkName: "component---src-pages-news-saveljevs-transfers-to-riga-js" */),
  "component---src-pages-news-sorokin-prolongs-till-2023-js": () => import("/vercel/path0/src/pages/news/sorokin_prolongs_till_2023.js" /* webpackChunkName: "component---src-pages-news-sorokin-prolongs-till-2023-js" */),
  "component---src-pages-news-sorokins-extended-contract-rfs-js": () => import("/vercel/path0/src/pages/news/sorokins_extended_contract_rfs.js" /* webpackChunkName: "component---src-pages-news-sorokins-extended-contract-rfs-js" */),
  "component---src-pages-news-sorokins-latvia-2-js": () => import("/vercel/path0/src/pages/news/sorokins_latvia_2.js" /* webpackChunkName: "component---src-pages-news-sorokins-latvia-2-js" */),
  "component---src-pages-news-sorokins-latvia-js": () => import("/vercel/path0/src/pages/news/sorokins_latvia.js" /* webpackChunkName: "component---src-pages-news-sorokins-latvia-js" */),
  "component---src-pages-news-sushkin-contract-ended-js": () => import("/vercel/path0/src/pages/news/sushkin_contract_ended.js" /* webpackChunkName: "component---src-pages-news-sushkin-contract-ended-js" */),
  "component---src-pages-news-sushkin-contract-js": () => import("/vercel/path0/src/pages/news/sushkin_contract.js" /* webpackChunkName: "component---src-pages-news-sushkin-contract-js" */),
  "component---src-pages-players-glushkov-js": () => import("/vercel/path0/src/pages/players/glushkov.js" /* webpackChunkName: "component---src-pages-players-glushkov-js" */),
  "component---src-pages-players-lasha-js": () => import("/vercel/path0/src/pages/players/lasha.js" /* webpackChunkName: "component---src-pages-players-lasha-js" */),
  "component---src-pages-players-lemajic-js": () => import("/vercel/path0/src/pages/players/lemajic.js" /* webpackChunkName: "component---src-pages-players-lemajic-js" */),
  "component---src-pages-players-maksimov-js": () => import("/vercel/path0/src/pages/players/maksimov.js" /* webpackChunkName: "component---src-pages-players-maksimov-js" */),
  "component---src-pages-players-osipov-js": () => import("/vercel/path0/src/pages/players/osipov.js" /* webpackChunkName: "component---src-pages-players-osipov-js" */),
  "component---src-pages-players-saveljevs-js": () => import("/vercel/path0/src/pages/players/saveljevs.js" /* webpackChunkName: "component---src-pages-players-saveljevs-js" */),
  "component---src-pages-players-sorokin-js": () => import("/vercel/path0/src/pages/players/sorokin.js" /* webpackChunkName: "component---src-pages-players-sorokin-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/vercel/path0/.cache/data.json")

