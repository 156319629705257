/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onClientEntry = () => {
  const isMobile = {
    Android: function() { return navigator.userAgent.match(/Android/i) ? true : false; },
    BlackBerry: function() { return navigator.userAgent.match(/BlackBerry/i) ? true : false; },
    iOS: function() { return navigator.userAgent.match(/iPhone|iPod/i) ? true : false; },
    Windows: function() { return navigator.userAgent.match(/IEMobile/i) ? true : false; },
    any: function() { return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows()); }
  };

  console.log("We've started!");
  if (!isMobile.any()) {
    document.body.classList.add("page-loaded");
  }
}